import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { PageContext } from '@content/types/pageContext';
import MetaGlobal from '@components/common/MetaGlobal';
import LayoutWrapper from './layout';
import { LocationDependent } from '@content/types/general';
import languages from '@content/json/languages.json';
import * as utils from '@utils';

/* fixes hash links */
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]', {
    speed: 300,
    speedAsDuration: true,
    easing: 'easeOutQuad',
  });
}

declare global {
  interface Window {
    easyPackAsyncInit: Function;
  }
}

interface GlobalLayoutProps extends LocationDependent {
  path: string;
  pageContext: PageContext;
}

class IndexLayout extends Component<GlobalLayoutProps> {
  state = {
    loaded: false,
    cookieBannerOpened: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 100);

    setTimeout(this.checkCookie, 600);
    this.checkLang();
  }

  checkCookie = () => {
    if (Cookies.get('ipos-cookies')) {
      this.setState({ cookieBannerOpened: false });
    } else {
      this.setState({ cookieBannerOpened: true });
    }
  };

  checkLang = () => {
    const cookieLang = Cookies.get('ipos-language')
      ? Cookies.get('ipos-language')
      : languages.default;

    if (
      this.props.pageContext.langcode &&
      cookieLang !== this.props.pageContext.langcode &&
      typeof window !== 'undefined'
    ) {
      window.location.href = utils.langLink(
        cookieLang,
        this.props.pageContext.otherLangUrl[cookieLang]
      );
    }
  };

  acceptCookies = () => {
    Cookies.set('ipos-cookies', true, { expires: 365 });
    this.setState({ cookieBannerOpened: false });
  };

  closeCookieBanner = () => this.setState({ cookieBannerOpened: false });

  render() {
    return (
      <>
        <MetaGlobal>
          {process.env.IPOS_ENV === 'production' && (
            <script>{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TTK46RW');
            `}</script>
          )}

          {process.env.IPOS_ENV !== 'local' && (
            /* <!-- BEGIN polyfill callpage.io widget --> */
            <script>{`(function () {var script = document.createElement('script');script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js';script.async = false;document.head.appendChild(script);}())`}</script>
            /* <!-- END polyfill callpage.io widget --> */
          )}

          {process.env.IPOS_ENV !== 'local' && (
            /* <!-- BEGIN callpage.io widget --> */
            <script>{`var __cp = {"id":"YPICHMDGk1rsPHuTwZrK6BqfQgqN6gnqvaJQwDgyXS4","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`}</script>
            /* <!-- END callpage.io widget --> */
          )}

          {process.env.IPOS_ENV !== 'local' && (
            /* <!-- BEGIN overwrite position callpage.io widget --> */
            <script>{`(function(window, document) {if(window.callpage) {window.callpage('event.onReady', function() {var elCP = document.querySelector("#callpage div[data-test='widget-button-section']"); if(elCP) elCP.style.cssText = 'top: var(--callpage-top) !important; left: var(--callpage-left) !important;';});}})(window, document);`}</script>
            /* <!-- END overwrite position callpage.io widget --> */
          )}

          {/* <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=6bf2f621-bc07-4a09-8b62-41135a6ffc24"
            async
            defer
          ></script> */}

          {/* InPost integration */}
          <script async src="https://geowidget.easypack24.net/js/sdk-for-javascript.js"></script>
          <link rel="stylesheet" href="https://geowidget.easypack24.net/css/easypack.css" />
        </MetaGlobal>
        <LayoutWrapper
          {...this.props}
          opened={this.state.cookieBannerOpened}
          acceptCookies={this.acceptCookies}
          closeCookieBanner={this.closeCookieBanner}
        />
      </>
    );
  }
}

export default IndexLayout;
