import React from 'react';
import Container from './Container';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Logo from '@components/common/Logo';

import HeaderMenuDesktop from './HeaderMenuDesktop';
import HamburgerMenu from '@components/layout/HeaderMenuHamburger';
import { colors, mixins, respondTo, respondFrom, fluidRange, breakpoints } from '@styles';
import { MenuData } from '@content/types/layout';
import HeaderSubMenu from './HeaderSubMenu';

export const headerHeightMobile = 50;
export const headerHeightDesktop = 100;

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: ${colors.white};

  > div {
    z-index: 2;
  }

  .body--menu-static & {
    position: relative;
    /* z-index: 1; */
  }

  ${respondTo(
    breakpoints.mobileMenuThreshold,
    css`
      &:after {
        /* header shadow */
        content: '';
        display: block;
        ${mixins.absoluteFill}
        z-index: 5;
        pointer-events: none;
      }
    `
  )}
`;

const StyledHeaderContentWraper = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;

  ${fluidRange('height', `${headerHeightMobile}px`, `${headerHeightDesktop}px`)}
  ${fluidRange('lineHeight', `${headerHeightMobile}px`, `${headerHeightDesktop}px`)}
`;

const StyledMenuDesktop = styled(HeaderMenuDesktop)`
  ${respondTo(
    breakpoints.mobileMenuThreshold,
    css`
      display: none;
    `
  )}
`;

const StyledHamburger = styled(HamburgerMenu)`
  position: relative;
  z-index: 4;

  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      display: none;
    `
  )}
`;

interface HeaderProps {
  content: MenuData;
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;
  showSubMenu: boolean;
}

export default (props: HeaderProps) => (
  <StyledHeader>
    <Container>
      <StyledHeaderContentWraper>
        <Logo langcode={props.langcode} urls={props.urls} />

        <StyledMenuDesktop
          content={props.content}
          langcode={props.langcode}
          urls={props.urls}
          otherLangUrl={props.otherLangUrl}
        />
        <StyledHamburger
          content={props.content}
          langcode={props.langcode}
          urls={props.urls}
          otherLangUrl={props.otherLangUrl}
        />
      </StyledHeaderContentWraper>
    </Container>
    {props.showSubMenu && <HeaderSubMenu content={props.content} />}
  </StyledHeader>
);
