import * as React from 'react';
import styled from '@emotion/styled';

import { widths, dimensions, css, respondFrom, breakpoints } from '@styles';
import { getEmSize } from '@styles';

const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${getEmSize(widths.xl + 80)}em;
  padding: 0 ${getEmSize(dimensions.containerPadding)}em;

  ${({ mode }) =>
    mode &&
    mode.breakpoints &&
    mode.width === 'narrow' &&
    respondFrom(
      breakpoints[mode.breakpoints],
      css`
        padding: 0 ${getEmSize(dimensions.containerPadding / 2)}em;
      `
    )};

  & & {
    padding: 0; /* nested containers */
  }
`;

interface ContainerProps extends StyledContainerProps {
  className?: string;
}

interface StyledContainerProps {
  mode?: {
    breakpoints: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    width: 'narrow' | 'full';
  };
}

const Container: React.FC<ContainerProps> = ({ children, className, mode }) => (
  <StyledContainer className={className} mode={mode}>{children}</StyledContainer>
);

export default Container;
