import React from 'react';
import Container from './Container';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors, mixins, respondFrom, breakpoints, respondTo } from '@styles';
import { Link } from '@utils';
import ScrollToTop from '@components/common/ScrollToTop';
import logo from '@assets/svg/logo.svg';
import { FooterData } from '@content/types/layout';
import * as utils from '@utils';
import Image from 'gatsby-image';
import SVG from 'react-inlinesvg';

const StyledFooter = styled.footer``;

const StyledFooterWrapper = styled.div`
  position: relative;
  margin-right: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    background-color: ${colors.ui.whisper};
    border-bottom: ${colors.green.dark} solid 6px;
    z-index: -1;
  }
`;

const StyledFooterTop = styled.div`
  padding-top: 40px;
  padding-right: 20px;
  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 65px;
      padding-right: 194px;
    `
  )}
`;

const StyledFooterBottom = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: column;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `
  )}
`;

const StyledSocialLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;

  li {
    display: flex;

    a {
      display: inline-block;
      position: relative;
      padding: 0 6px;

      svg {
        display: block;
      }

      &:before {
        content: '';
        display: inline-block;
        ${mixins.absoluteCenter}
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: ${colors.text.default} solid 4px;

        ${mixins.transitionDefault}
        transform: translate(-50%, -50%) scale(0.9);
        transform-origin: center center;
      }

      &:hover {
        &:before {
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }
`;

const StyledLogoLink = styled(Link)`
  display: inline-flex;
  img {
    width: 74px;
  }
`;

const StyledScrollToTop = styled(ScrollToTop)`
  width: 88px;
  height: 88px;
  background-image: linear-gradient(to left, ${colors.green.light}, ${colors.green.dark});
  color: white;
  cursor: pointer;
  transform: translateX(50%);

  > * {
    ${mixins.absoluteCenter}
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
    `
  )}
`;

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  h5 {
    font-size: 20px;
    line-height: 1em;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &:before {
    content: '';
    width: 30px;
    height: 1px;
    margin-right: 10px;
    background-color: ${colors.ui.light};
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      font-size: 22px;
      margin-bottom: 32px;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        margin-left: 10px;
        background-color: ${colors.ui.light};
      }
      h5 {
        flex-shrink: 0;
      }
    `
  )}
`;

const StyledColumn = styled.div`
  flex: 1;
  margin-right: 10px;
  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: calc(100% / 3);
      margin-right: 70px;
    `
  )}
`;

const StyledColumnHeading = styled.h6`
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const StyledColumnText = styled.div`
  ${respondTo(
    breakpoints.lg,
    css`
      margin-bottom: 25px;
    `
  )}
  p {
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 0;
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 25px;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  &:before {
    content: '';
    min-width: 14px;
    height: 1px;
    background-color: #149944;
    margin-right: 10px;
    margin-top: 10px;
  }
  a {
    color: inherit;
  }
`;

const StyledFooterItems = styled.div`
  display: flex;
  align-items: center;

  ${respondTo(
    breakpoints.lg,
    css`
      align-self: flex-end;
    `
  )}
`;

interface FooterProps {
  content: FooterData;
  langcode: string;
  urls: Array<string>;
}

export default (props: FooterProps) => {
  return (
    <>
      {props.content && (
        <StyledFooter>
          <Container>
            <StyledFooterWrapper>
              <StyledFooterTop>
                {props.content.sections &&
                  props.content.sections.map((section, index) => (
                    <React.Fragment key={index}>
                      {section.header && (
                        <StyledSectionHeader>
                          <h5>{section.header}</h5>
                        </StyledSectionHeader>
                      )}
                      <StyledRow>
                        {section.components &&
                          section.components.map((component, index) => (
                            <StyledColumn key={index}>
                              {component.heading && (
                                <StyledColumnHeading>{component.heading}</StyledColumnHeading>
                              )}
                              {component.type === 'headingAndText' && (
                                <>
                                  {component.text && (
                                    <StyledColumnText>
                                      {utils.SafeHtml(component.text)}
                                    </StyledColumnText>
                                  )}
                                  {component.links && (
                                    <StyledList>
                                      {component.links.map((link, index) => (
                                        <StyledListItem key={index}>
                                          <a
                                            href={link.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {link.title}
                                          </a>
                                        </StyledListItem>
                                      ))}
                                    </StyledList>
                                  )}
                                </>
                              )}
                              {component.type === 'headingAndLinks' && component.links && (
                                <StyledList>
                                  {component.links.map((link, index) => (
                                    <StyledListItem key={index}>
                                      <Link to={link.url}>{link.title}</Link>
                                    </StyledListItem>
                                  ))}
                                </StyledList>
                              )}
                              {component.type === 'headingAndAgreements' && component.agreements && (
                                <StyledList>
                                  {component.agreements.map((agreement, index) => (
                                    <StyledListItem key={index}>
                                      <a
                                        href={agreement.PDFUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {agreement.PDFtitle}
                                      </a>
                                    </StyledListItem>
                                  ))}
                                </StyledList>
                              )}
                            </StyledColumn>
                          ))}
                      </StyledRow>
                    </React.Fragment>
                  ))}
              </StyledFooterTop>
              <StyledFooterBottom>
                {props.content.socialLinks && (
                  <StyledSocialLinks>
                    {props.content.socialLinks
                      .filter(socialLink => (socialLink.icon && socialLink.icon.childImageSharp) || socialLink.filePublicURL)
                      .map((socialLink, index) => (
                        <li key={index}>
                          <a href={socialLink.url} target="_blank" rel="noopener noreferrer">
                            {socialLink.icon && socialLink.icon.childImageSharp && <Image {...socialLink.icon.childImageSharp} />}
                            {socialLink.filePublicURL && <SVG src={socialLink.filePublicURL} />}
                          </a>
                        </li>
                      ))}
                  </StyledSocialLinks>
                )}
                <StyledFooterItems>
                  <StyledLogoLink
                    to={utils.langLink(props.langcode, props.urls ? props.urls[20] : '/')}
                  >
                    <img src={logo} alt="IPOS logo" />
                  </StyledLogoLink>
                  <StyledScrollToTop />
                </StyledFooterItems>
              </StyledFooterBottom>
            </StyledFooterWrapper>
          </Container>
        </StyledFooter>
      )}
    </>
  );
};
