import { createReducer } from 'redux-act';
import moment from 'moment';
import * as configuratorActions from './actions';
import * as cennikActions from '../cennik/actions';
import { ConfiguratorState, BasketDiscountCoupon } from './types';
import { Accessory, Product, ProductColor, Funding, Tariff, Fiscalization } from '@store/types';
import { ConfiguratorActions } from '@store/actions';

const getPersistExpireAt = () => {
  return moment()
    .add(30, 'days')
    .toDate();
};

export const defaultState: ConfiguratorState = {
  persistExpiresAt: getPersistExpireAt(),

  currentProductIndex: 0,
  basket: {
    products: [],
    fundings: [],
    tariff: null,
    tariffLocalizations: 1,
    accessories: [],
    discountCoupon: undefined,
    fiscalization: null
  },
  paymentType: undefined,
  formData: {
    nip: '',
    company: '',
    name: '',
    phone: '',
    email: '',

    address1Street: '',
    address1HouseNumber: '',
    address1ApartmentNumber: '',
    address1PostalCode: '',
    address1City: '',

    recipientName: '',
    address2Street: '',
    address2HouseNumber: '',
    address2ApartmentNumber: '',
    address2PostalCode: '',
    address2City: '',

    // sameDeliveryAddress: true,
    agreements: []
  },
  formCalendar: {
    selectServiceDate: false,
    serviceDate: '',
  },
  formValidation: {
    nip: { required: true, match: true },
    company: { required: true, match: true },
    name: { required: true, match: true },
    phone: { required: true, match: true },
    email: { required: true, match: true },
    address1Street: { required: true, match: true },
    address1HouseNumber: { required: true, match: true },
    address1ApartmentNumber: { required: true, match: true },
    address1PostalCode: { required: true, match: true },
    address1City: { required: true, match: true },
    recipientName: { required: true, match: true },
    address2Street: { required: true, match: true },
    address2HouseNumber: { required: true, match: true },
    address2ApartmentNumber: { required: true, match: true },
    address2PostalCode: { required: true, match: true },
    address2City: { required: true, match: true },
  },
  nipControlSum: true,
  summaryPanelHiden: false,
  loading: false,
  delivery: {
    deliveryType: 'sameAddress',
    locker: null,
  },
  version: 0,
};

const configuratorReducer = createReducer<typeof defaultState>({}, defaultState);

// returns state with updated persistExpiresAt
const updateStateWithExpireDate = (state: ConfiguratorState) => ({
  ...state,
  persistExpiresAt: getPersistExpireAt(),
});

configuratorReducer.on(
  [configuratorActions.basketAddProduct, cennikActions.basketAddProduct] as any, // ouch, we lose payload type
  (state, product: Product) => ({
    ...updateStateWithExpireDate(state),
    basket: {
      ...state.basket,
      products: [
        {
          ...product,
          accessories: state.basket.accessories.length ? state.basket.accessories : [],
          currentColor: product.availableColors.length ? product.availableColors[0] : undefined,
        },
        ...state.basket.products,
      ],
      // make sure to clear separate accessories list
      accessories: [],
    },
    currentProductIndex:
      typeof state.currentProductIndex === 'number' ? state.currentProductIndex : 0,
  })
);

configuratorReducer.on(
  [configuratorActions.basketRemoveProduct, cennikActions.basketRemoveProduct] as any,
  (state, index: number) => {
    const updatedProducts = state.basket.products;
    updatedProducts.splice(index, 1);
    return {
      ...updateStateWithExpireDate(state),
      basket: updatedProducts.length
        ? {
            ...state.basket,
            products: updatedProducts,
          }
        : {
            // no more products in basket - clear also other stuff in basket
            ...defaultState.basket,
          },
      // last product removed? clear current product index
      // (else - index will point the next product)
      // currentProductIndex: state.basket.products.length > 1 ? state.currentProductIndex : null,
    };
  }
);

configuratorReducer.on(
  [configuratorActions.setCurrentProductIndex, cennikActions.setCurrentProductIndex] as any,
  (state, index: number) => ({
    ...updateStateWithExpireDate(state),
    currentProductIndex: index,
  })
);

configuratorReducer.on(
  [configuratorActions.basketSetCurrentColor, cennikActions.basketSetCurrentColor] as any,
  (state, color: ProductColor) => {
    state.basket.products[state.currentProductIndex].currentColor = color;
    return {
      ...updateStateWithExpireDate(state),
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketAddAccessory, cennikActions.basketAddAccessory] as any,
  (state, { accessory, productIndex }) => {
    const newState = { ...state };
    let accessoriesRef;

    // add to particular product
    if (productIndex || state.basket.products.length) {
      const productIndex_ =
        typeof productIndex === 'number' ? productIndex : state.currentProductIndex;

      accessoriesRef =
        typeof productIndex_ === 'number' && newState.basket.products[state.currentProductIndex]
          ? newState.basket.products[state.currentProductIndex].accessories
          : null;
    }
    // no product - add to separate list
    else {
      accessoriesRef = newState.basket.accessories;
    }

    if (accessoriesRef) {
      accessoriesRef.push(accessory);
    }
    return {
      ...newState,
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketRemoveAccessory, cennikActions.basketRemoveAccessory] as any,
  (state, { accessory, productIndex }) => {
    const newState = { ...state };
    let accessoriesRef: Accessory[] | null;

    // remove from particular product
    if (productIndex || state.basket.products.length) {
      const productIndex_ =
        typeof productIndex === 'number' ? productIndex : state.currentProductIndex;
      accessoriesRef =
        typeof productIndex_ === 'number' && newState.basket.products[state.currentProductIndex]
          ? newState.basket.products[state.currentProductIndex].accessories
          : null;
    }
    // no product - remove from separate list
    else {
      accessoriesRef = newState.basket.accessories;
    }

    if (accessoriesRef) {
      const accessoryIndex = accessoriesRef.findIndex(a => a.id === accessory.id);
      accessoriesRef.splice(accessoryIndex, 1);
    }
    return {
      ...newState,
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketAddFunding, cennikActions.basketAddFunding] as any,
  (state, _funding: Funding) => {
    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        fundings: [...state.basket.fundings, _funding],
      },
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketRemoveFunding, cennikActions.basketRemoveFunding] as any,
  (state, _funding: Funding) => {
    const fundings = [...state.basket.fundings];
    const index = fundings.findIndex(f => f.id === _funding.id);
    fundings.splice(index, 1);

    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        fundings,
      },
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketSetTariff, cennikActions.basketSetTariff] as any,
  (state, _tariff: Tariff) => {
    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        tariff: _tariff,
      },
    };
  }
);

configuratorReducer.on(
  [configuratorActions.basketSetFiscalization, cennikActions.basketSetFiscalization] as any,
  (state, _fiscalization: Fiscalization) => {
    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        fiscalization: _fiscalization,
      },
    };
  }
);

configuratorReducer.on(configuratorActions.basketSetTariffLocalizations,
  (state, _tariffLocalizations: number) => {
    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        tariffLocalizations: _tariffLocalizations,
      },
    };
  }
);

configuratorReducer.on(configuratorActions.replaceBasket, (state, _cennik: ConfiguratorState) => {
  return {
    ...updateStateWithExpireDate(state),
    basket: {
      ..._cennik.basket,
    },
    paymentType: _cennik.paymentType
  };
});

configuratorReducer.on(
  [configuratorActions.clearBasket, cennikActions.clearBasket] as any,
  state => {
    return {
      ...updateStateWithExpireDate({ ...defaultState }),
    };
  }
);

configuratorReducer.on(
  configuratorActions.setDiscountCoupon,
  (state, _coupon: BasketDiscountCoupon) => {
    return {
      ...updateStateWithExpireDate(state),
      basket: {
        ...state.basket,
        discountCoupon: { ..._coupon },
      },
    };
  }
);

configuratorReducer.on(configuratorActions.removeDiscountCoupon, state => {
  return {
    ...updateStateWithExpireDate(state),
    basket: {
      ...state.basket,
      discountCoupon: defaultState.basket.discountCoupon,
    },
  };
});

configuratorReducer.on(configuratorActions.getFormData, (state, formData) => ({
  ...updateStateWithExpireDate(state),
  formData,
}));

configuratorReducer.on(configuratorActions.getFormCalendar, (state, formCalendar) => ({
  ...updateStateWithExpireDate(state),
  formCalendar,
}));

configuratorReducer.on(configuratorActions.getFormValidation, (state, formValidation) => ({
  ...updateStateWithExpireDate(state),
  formValidation,
}));

configuratorReducer.on(
  [configuratorActions.setPaymentType, cennikActions.setPaymentType] as any,
  (state, _paymentType: string) => ({
    ...updateStateWithExpireDate(state),
    paymentType: _paymentType,
  })
);

configuratorReducer.on(configuratorActions.toggleSummaryPanel, (state, value) => ({
  ...updateStateWithExpireDate(state),
  summaryPanelHiden: value,
}));

configuratorReducer.on(
  [configuratorActions.setLoading, cennikActions.setLoading] as any,
  (state, value: boolean) => ({
    ...state,
    loading: value,
  })
);

configuratorReducer.on(ConfiguratorActions.checkNipControlSum, (state, value) => ({
  ...updateStateWithExpireDate(state),
  nipControlSum: value,
}));

configuratorReducer.on(ConfiguratorActions.setDelivertType, (state, value) => ({
  ...updateStateWithExpireDate(state),
  delivery: value,
}));

configuratorReducer.on(ConfiguratorActions.setVersionNumber, (state, value) => ({
  ...updateStateWithExpireDate(state),
  version: value,
}));

export default configuratorReducer;
