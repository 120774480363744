import * as React from 'react';
import styled from '@emotion/styled';

import { dimensions, colors, animation, mixins } from '@styles';
import Icon from './Icon';

const StyledButtonLink = styled.button`
  display: inline-block;
  text-align: center;
  padding: 1em 2.6em 0.9em;
  text-transform: uppercase;
  text-decoration: none !important;
  font-weight: 500;
  letter-spacing: -0.025em; /* 0.4px / 16px */
  color: ${colors.white};
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  border: none;

  transition: ${animation.fast}ms ease-out;
  transition-property: background-position, transform;

  &:focus {
    outline: none;
  }

  // color versions
  &.color-primary {
    ${mixins.gradientGreen}
  }

  &.color-text {
    background-image: none;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    color: ${colors.green.default};
    letter-spacing: 0;
  }

  &.color-text-light {
    background-image: none;
    padding-left: 0;
    padding-right: 0;
    color: ${colors.text.article};
    letter-spacing: 0;
  }

  &.color-outlined {
    background-image: none;
    background: transparent;
    color: ${colors.black};
    border: 1px solid ${colors.black};
  }

  &.disabled {
    background-image: none;
    background-color: ${colors.text.light};
    pointer-events: none;
  }

  // size versions
  &.size-smaller {
    font-size: ${dimensions.fontSize.smaller}px;
  }

  &.size-small {
    font-size: ${dimensions.fontSize.small}px;
  }

  &.size-medium {
    font-size: ${dimensions.fontSize.regular}px;
  }

  &.size-basket {
    padding: 1.25em 0.8em 1.15em;

    .button-icon {
      margin-left: 10px;
    }
  }

  /* &.no-click {
    pointer-events: none;
  } */

  .button-text {
    position: relative;
    display: inline-block;

    transition: ${animation.fast}ms ease-out;
    transition-property: transform;
  }

  .button-icon {
    margin-left: 20px;

    transition: ${animation.fast}ms ease-out;
    transition-property: transform;
  }

  &:not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      background-position: -20% center;

      .button-text {
        transform: translateX(1px);
      }
      .button-icon {
        transform: translateX(4px);
      }
    }
    &:active {
      transform: translateX(1px);
    }
  }

  &.to-left {
    .button-icon {
      margin-left: 0;
      margin-right: 20px;
    }

    &:not(.disabled) {
      &:hover,
      &:active,
      &:focus {
        background-position: 20% center;

        .button-text {
          transform: translateX(-1px);
        }
        .button-icon {
          transform: translateX(-4px);
        }
      }
      &:active {
        transform: translateX(-1px);
      }
    }
  }
`;

interface ButtonLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'text' | 'text-light' | 'inactive' | 'outlined';
  size?: 'smaller' | 'small' | 'medium' | 'basket';
  icon?: 'arrow-right' | 'arrow-left';
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  className,
  color,
  size,
  icon,
  disabled,
  type,
  ...otherProps
}) => (
  <StyledButtonLink
    {...otherProps}
    type={type}
    className={`
      ${className || ''}
      ${'color-' + (color || 'primary')}
      ${'size-' + (size || 'medium')}
      ${disabled ? 'disabled' : ''}
    `}
  >
    {icon && icon === 'arrow-left' ? (
      <Icon className="button-icon" type="arrow-left" inheritColor={true}></Icon>
    ) : (
      ''
    )}

    <span className="button-text">{children}</span>

    {icon && icon === 'arrow-right' ? (
      <Icon className="button-icon" type="arrow-right" inheritColor={true}></Icon>
    ) : (
      ''
    )}
  </StyledButtonLink>
);

export default ButtonLink;
