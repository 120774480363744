import * as React from 'react';
import styled from '@emotion/styled';

import HeaderMenuContent from './HeaderMenuContent';
import { colors, animation, dimensions, breakpoints, fluidRange } from '@styles';
import { MenuData } from '@content/types/layout';

const StyledHeaderMenuDesktop = styled.nav`
  flex: 1 0 0;
  display: flex;

  ul {
    margin-left: -20px;
    margin-right: -20px;
    white-space: nowrap;

    li {
      display: inline-block;

      a {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        font-size: ${dimensions.fontSize.small}px;

        ${fluidRange('paddingLeft', '12px', '16px', breakpoints.mobileMenuThreshold)}
        ${fluidRange('paddingRight', '12px', '16px', breakpoints.mobileMenuThreshold)}

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: ${colors.ui.whisper};
          opacity: 0;
          transform: scaleY(0);
          transform-origin: top center;
          z-index: -1;

          transition: ${animation.fast}ms ease-out;
          transition-property: opacity, transform, background-color;
        }

        &:hover, &:focus, &:active, &.active {

          &:after {
            opacity: 1;
            transform: none;
          }
        }
        &:active, &.active {
          &:after {
            /* background-color: ${colors.ui.silent}; */
          }
        }

      }

    }
  }
`;

const StyledHeaderMenuContent = styled(HeaderMenuContent)`
  flex: 1 0 0;
  display: flex;
  justify-content: space-between; /* menus to left and right */
`;

interface DesktopMenuProps {
  className?: string;
  content: MenuData;
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;
}

class HeaderMenuDesktop extends React.Component<DesktopMenuProps> {
  constructor(props: DesktopMenuProps) {
    super(props);
  }

  render() {
    return (
      <StyledHeaderMenuDesktop className={`${this.props.className}`}>
        <StyledHeaderMenuContent
          content={this.props.content}
          langcode={this.props.langcode}
          urls={this.props.urls}
          otherLangUrl={this.props.otherLangUrl}
        />
      </StyledHeaderMenuDesktop>
    );
  }
}

export default HeaderMenuDesktop;
