import { FileImageSharp } from '@content/types/api';
import get from 'lodash.get';

export const transformLayoutData = layoutData => {
  const menu = {};
  const footer = {};
  const cookieBanner = {};

  layoutData.allSiteSettingEntityFooter.edges.forEach(edge => {
    const data = edge.node;

    footer[edge.node.langcode] = {
      sections: get(data, 'relationships.field_footer_sections', []).map(sections => {
        return {
          header: get(sections, 'field_section_header', ''),
          components: (get(sections, 'relationships.field_section_components') || []).map(section => {
            if (section.__typename === 'paragraph__heading_and_text') {
              return {
                type: 'headingAndText',
                heading: get(section, 'field_heading_and_text_heading', ''),
                text: get(section, 'field_heading_and_text_text.value', ''),
                links: (get(section, 'relationships.field_heading_and_text_links') || []).map(link => {
                  return {
                    title: get(link, 'field_link_with_text_text', ''),
                    url: get(link, 'field_link_with_text_link', ''),
                  }
                })
              };
            } else if (section.__typename === 'paragraph__heading_and_links') {
              return {
                type: 'headingAndLinks',
                heading: get(section, 'field_heading_and_links_heading', ''),
                links: (get(section, 'relationships.field_heading_and_links_links') || []).map(link => {
                  return {
                    title: get(link, 'field_link_with_text_text', ''),
                    url: get(link, 'field_link_with_text_link', ''),
                  }
                }),
              };
            } else if (section.__typename === 'paragraph__heading_and_agreements') {
              return {
                type: 'headingAndAgreements',
                heading: get(section, 'field_heading_and_agrmnt_heading', ''),
                agreements: (get(section, 'relationships.field_agreements') || []).map(agreement => {
                  return {
                    PDFtitle: get(agreement, 'agreement_pdf_title', ''),
                    PDFUrl: get(agreement, 'relationships.agreement_pdf.localFile.url', ''),
                  }
                }),
              };
            }
          })
        }
      }),
      socialLinks: get(data, 'relationships.field_footer_social_links', []).map(socialLink => {
        const socialLinkIcon = get(socialLink, 'relationships.field_social_icon') as FileImageSharp;
        return {
          title: get(socialLink, 'field_social_link.title', ''),
          url: get(socialLink, 'field_social_link.uri', ''),
          icon: socialLinkIcon ? socialLinkIcon.localFile : null,
          filePublicURL: get(socialLink, 'relationships.field_social_icon.localFile.publicURL', ''),
        }
      })
    };
  });

  layoutData.allSiteSettingEntityMenu.edges.forEach(edge => {
    const data = edge.node;

    menu[edge.node.langcode] = {
      text1: get(data, 'field_settings_text_1', ''),
      text2: get(data, 'field_settings_text_2', ''),
      text3: get(data, 'field_settings_tresc.value', ''),
      text4: get(data, 'field_settings_text_3', ''),
      text5: get(data, 'field_settings_text_4', ''),
      text6: get(data, 'field_settings_text_5', ''),
      text7: get(data, 'field_settings_text_6', ''),
      text8: get(data, 'field_settings_text_7', ''),
      translationSwitch: get(
        layoutData.allSiteSettingEntityAdministration.edges[0],
        'node.field_translation_boolean',
        false
      ),
      optionalField: {
        label: get(data, 'field_optional_label', ''),
        url: get(data, 'field_optional_url', ''),
      },
      subMenu: {
        enabled: get(data, 'relationships.field_menu_icons_belt.field_icons_belt_enabled', false),
        heading: get(data, 'relationships.field_menu_icons_belt.field_icons_belt_heading', ''),
        showUrls: get(data, 'relationships.field_menu_icons_belt.field_icons_belt_show_urls', []),
        icons: get(data, 'relationships.field_menu_icons_belt.relationships.field_icons_belt_icons', []).map(icon => {
          return {
            text: get(icon, 'field_linked_icon_text', ''),
            url: get(icon, 'field_linked_icon_url', ''),
            filePublicURL: get(icon, 'relationships.field_linked_icon_icon.localFile.publicURL', ''),
          }
        }),
      }
    };
  });

  layoutData.allSiteSettingEntityPanelCookies.edges.forEach(edge => {
    const data = edge.node;

    cookieBanner[edge.node.langcode] = {
      paragraph: get(data, 'field_settings_tresc.value', ''),
      button: get(data, 'field_settings_button_text', ''),
    };
  });

  return {
    menu,
    footer,
    cookieBanner,
  };
};
