import { createSelector } from 'reselect';
import { ConfiguratorState } from '@store/types';
import { defaultState } from './reducers';

import { BasketProduct } from './types';

/* --- selectors */
const stateSelector = (state?: ConfiguratorState) => state || defaultState;

export const currentProduct = (createSelector(
  [stateSelector],
  state => {
    return state.basket.products.find((_prod, i) => i === state.currentProductIndex) || null;
  }
) as unknown) as () => BasketProduct | null;
