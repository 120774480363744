import { dimensions, fonts, colors, breakpoints } from './variables';
import { getEmSize } from './mixins';
import { css } from '@emotion/core';
import { fluidRange, respondTo, respondFrom } from '@styles';

export const normalize = css`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html {
    font-size: ${dimensions.fontSize.regular}px !important;
    line-height: ${dimensions.lineHeight.regular} !important;
  }
  body {
    width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: ${fonts.default};
    font-weight: 300;
    color: ${colors.text.default};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    &.noscroll {
      overflow: hidden;
    }

    &.body-configurator {
      ${respondFrom(
        breakpoints.lg,
        css`
          overflow: hidden;
        `
      )}
    }
  }
  a {
    color: ${colors.green.default};
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }
  figure {
    margin: 2rem 0;
  }
  figcaption {
    font-size: 80%;
  }
  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${colors.ui.light};
    font-size: 85%;
    border-collapse: collapse;
  }
  td,
  th {
    padding: 0.25rem 0.5rem;
    border: 1px solid ${colors.ui.light};
  }
  th {
    text-align: left;
  }
  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${colors.ui.whisper};
        }
        tr {
          background-color: ${colors.ui.whisper};
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }
  h1 {
    ${fluidRange('fontSize', '40px', '62px')}
  }
  h2 {
    ${fluidRange('fontSize', '30px', '40px')}
  }
  h3 {
    ${fluidRange('fontSize', '25px', '30px')}
  }
  h4,
  h5,
  h6 {
    ${fluidRange('fontSize', '20px', '24px')}
  }
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  strong {
    font-weight: 700;
  }
  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-bottom: 0.5rem;
  }
  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.ui.light};
  }
  blockquote {
    margin: 0.8rem 0;
    padding: 0.5rem 1rem;
    border-left: 0.25rem solid ${colors.ui.light};
    color: ${colors.text.light};
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media (min-width: ${getEmSize(breakpoints.md)}em) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }

  .gatsby-image-wrapper {
    display: block !important;
  }

  .inactive {
    opacity: 0.5;
    pointer-events: none;
  }

  .hoverable {
    cursor: pointer;
  }

  .tl-wrapper {
    z-index: unset !important;
  }

  .blocked {
    pointer-events: none;

    > div {
      opacity: 0.25;
    }
  }

  .zEWidget-launcher,
  .zEWidget-webWidget {
    ${respondTo(
      breakpoints.lg,
      css`
        display: none !important;
      `
    )}
  }

  #widget-modal {
    background-color: ${colors.white};
  }

  // CallPage style
  body {
      --callpage-top: calc(92% - 110px);
      --callpage-left: 40px;
      &.body-configurator {
        --callpage-top: 50px;
        --callpage-left: 15px;
        ${respondFrom(
          breakpoints.xl,
          css`
            --callpage-top: 15px;
            --callpage-left: calc(100% - 532px);
          `
        )}
      }
    }
  }
`;
