export const colors = {
  text: {
    default: '#393939',
    medium: '#535353',
    light: '#6A6A6A',
    disabled: '#AAA',
    article: '#808080',
  },
  ui: {
    light: '#C9C9C9',
    whisper: '#F8F8F8',
    silent: '#FDFDFD',
    accent: '#1DA239',
  },
  green: {
    dark: '#009944',
    medium: '#009900',
    default: '#1DA239',
    light: '#4EBE65',
    subtle: '#86ce94',
    summary: '#2faf58',
    pastel: '#47bb62'
  },
  blue: {
    default: '#02A2DC',
    light: '#17b7ed',
    dark: '#007ca9',
  },
  red: {
    error: '#ff7979'
  },
  white: '#fff',
  black: '#000',
  smoke: '#f2f2f2',
  grayDark: '#aaaaaa',
  grayMedium: '#979797',
  gray: '#E1E1E1',
  grayNurse: '#f8f9f8;',
  grayLight: '#dedede',
  error: '#e20d18',
  calendar: '#393939',
  sliderGradient: '#fcfdfc',
  summaryBorder: '#555555',
  infoMessage: 'rgba(226, 13, 24, 0.13)',
};

export const fonts = {
  default: 'museo-sans, Arial, sans-serif',
};

export const breakpoints = {
  // xs: 0,
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,

  mobileMenuThreshold: 1200,
  desktopGoToTopMax: 1480,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1280,
  articleMax: 730,
};

export const dimensions = {
  fontSize: {
    large: 18,
    regular: 16,
    small: 14,
    smaller: 12,
    tiny: 10,
  },
  lineHeight: {
    regular: 1.3,
    heading: 1.2,
  },

  containerPadding: 32,
  boxesSpacing: 16,
};

export const animation = {
  fast: 200,
  slow: 350,
  subtle: 500,
};

export const heights = {
  header: 100,
};
