import React, { useMemo } from 'react';
import { Provider } from 'react-redux';

import MainLayout from './main';
import ConfiguratorLayout from './configurator';
import CookieBanner from '@components/layout/CookieBanner';

import Store from '../store';
import useLayoutQuery from '@graphql/queries/LayoutQuery';
import { PageContext } from '@content/types/pageContext';

interface LayoutProps {
  path: string;
  pageContext: PageContext;
  children: React.ReactElement;
  opened: boolean;
  acceptCookies: Function;
  closeCookieBanner: Function;
}

export default (props: LayoutProps) => {
  const { children, path, pageContext } = props;
  const layoutData = useLayoutQuery();

  const showSubMenu = useMemo(() => {
    const content = pageContext.langcode && layoutData.menu[pageContext.langcode];
    return content && content.subMenu.enabled && content.subMenu.showUrls.includes(path);
  }, [path, pageContext.langcode, layoutData]);

  return (
    <>
      <Provider store={Store.store}>
        {pageContext && pageContext.langcode ? ( // only 404 page don't have pageContext.lang
          <>
            {path !== undefined ? (
              <>
                {!pageContext.layout || pageContext.layout !== 'configurator' ? (
                  <MainLayout
                    layoutData={layoutData}
                    langcode={pageContext.langcode}
                    urls={pageContext.allUrls}
                    otherLangUrl={pageContext.otherLangUrl}
                    showSubMenu={showSubMenu}
                  >
                    {children}
                  </MainLayout>
                ) : (
                  <ConfiguratorLayout>{children}</ConfiguratorLayout>
                )}
              </>
            ) : (
              children
            )}
          </>
        ) : (
          // 404 page
          children
        )}
        <CookieBanner
          opened={props.opened}
          acceptCookies={props.acceptCookies}
          closeCookieBanner={props.closeCookieBanner}
          content={layoutData.cookieBanner[props.pageContext.langcode]}
        />
      </Provider>
    </>
  );
};
