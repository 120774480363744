import * as ConfiguratorActions from './configurator/actions';
import * as CennikActions from './cennik/actions';
import * as ContentActions from './content/actions';

export { ConfiguratorActions, CennikActions, ContentActions };

export default {
  configurator: ConfiguratorActions,
  cennik: CennikActions,
  content: ContentActions,
};
