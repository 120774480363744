import styled from '@emotion/styled';
import { breakpoints, colors, respondFrom, css, mixins, animation } from '@styles';
import React, { useEffect, useState } from 'react';
import Container from './Container';
import Slick from 'react-slick';
import { MenuData } from '@content/types/layout';

export const headerSubMenuHeightMobile = 144;
export const headerSubMenuHeightDesktop = 120;

const StyledSubMenu = styled.div<StyledSubMenuProps>`
  background-color: ${colors.grayNurse};
  height: ${headerSubMenuHeightMobile}px;
  transition: height 0.1s;
  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      height: ${headerSubMenuHeightDesktop}px;
    `
  )};

  ${({ hide }) =>
    hide &&
    css`
      height: 0px !important;
      overflow: hidden !important;
    `};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: ${headerSubMenuHeightMobile}px;
  padding: 12px 0;
  position: relative;

  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      height: ${headerSubMenuHeightDesktop}px;
      flex-direction: row;
      padding: 0;
    `
  )};
`;

const StyledTitle = styled.div`
  font-size: 14px;
  line-height: 1.3em;
  font-weight: 300;
  color: ${colors.text.default};

  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      font-size: 20px;
      min-width: 215px;
      max-width: 215px;
    `
  )};
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${colors.smoke};
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top center;
        z-index: -1;

        transition: ${animation.fast}ms ease-out;
        transition-property: opacity, transform, background-color;
      }
      &:hover {
        &:after {
          opacity: 1;
          transform: none;
        }
      }
    `
  )};
`;

const StyledIcon = styled.div`
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  svg, img {
    width: 50px;
    height: 50px;
  }
  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      width: 65px;
      height: 65px;
      svg, img {
        width: 65px;
        height: 65px;
      }
    `
  )};
`;

const StyledName = styled.div`
  font-size: 11px;
  line-height: 1em;
  font-weight: 500;
  color: #149a45;
  text-transform: uppercase;
  text-align: center;
`;

const StyledSlider = styled.div`
  width: 100%;
  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      width: calc(100% - 215px);
    `
  )};
  .slick {
    &-list {
      padding: 2px 0;
      overflow: hidden;

      ${respondFrom(
        breakpoints.mobileMenuThreshold,
        css`
          height: ${headerSubMenuHeightDesktop}px;
        `
      )};
    }

    &-track {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    &-slide {
      ${respondFrom(
        breakpoints.mobileMenuThreshold,
        css`
          position: relative;
          padding-top: 15px;
        `
      )};
      > div {
        height: 100%;
      }
    }

    &-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      width: 32px;
      font-size: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      background-color: ${colors.grayNurse};
      box-shadow: -16px 1px 16px -8px rgba(248, 249, 248, 1);

      &:focus {
        outline: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -4.5px;
        width: 9px;
        height: 9px;
        border-top: 1px solid #009944;
        border-right: 1px solid #009944;
        ${mixins.transitionDefault};
      }
    }

    &-prev {
      left: -32px;
      box-shadow: 16px 1px 16px -8px rgba(248, 249, 248, 1);

      ${respondFrom(
        breakpoints.mobileMenuThreshold,
        css`
          left: 215px;
        `
      )};

      &::after {
        left: 12px;
        transform: rotate(-135deg);
      }

      &:hover {
        &::after {
          transform: rotate(-135deg) scale(1.05);
        }
      }
    }

    &-next {
      right: -32px;
      box-shadow: -16px 1px 16px -8px rgba(248, 249, 248, 1);

      ${respondFrom(
        breakpoints.mobileMenuThreshold,
        css`
          right: 0;
        `
      )};

      &::after {
        right: 12px;
        transform: rotate(45deg);
      }

      &:hover {
        &::after {
          transform: rotate(45deg) scale(1.05);
        }
      }
    }

    &-disabled {
      pointer-events: none;
      opacity: 0;
    }
  }
`;

const StyledSliderItem = styled.div`
  a {
    text-decoration: none;
  }
`;

const MAX_SLIDES_TO_SHOW = 8;

interface SubMenuProps {
  content: MenuData;
}

interface StyledSubMenuProps {
  hide: boolean;
}

const HeaderSubMenu = (props: SubMenuProps) => {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setHide(true);
    } else {
      setHide(false);
    }
  };
  return (
    <StyledSubMenu hide={hide}>
      <Container>
        <StyledWrapper>
          {props.content.subMenu.heading && (
            <StyledTitle>{props.content.subMenu.heading}</StyledTitle>
          )}
          {props.content.subMenu.icons && (
            <StyledSlider>
              <Slick
                dots={false}
                infinite={false}
                speed={500}
                slidesToShow={
                  props.content.subMenu.icons.length > MAX_SLIDES_TO_SHOW
                    ? MAX_SLIDES_TO_SHOW
                    : props.content.subMenu.icons.length
                }
                slidesToScroll={1}
                arrows={true}
                responsive={[1400, 1200, 992, 768, 576].reduce(
                  (breakpoints: Array<object>, breakpoint: number, index: number) => {
                    const _index = index + 1;
                    if (props.content.subMenu.icons.length > MAX_SLIDES_TO_SHOW - _index) {
                      return [
                        ...breakpoints,
                        {
                          breakpoint,
                          settings: {
                            slidesToShow: MAX_SLIDES_TO_SHOW - _index,
                          },
                        },
                      ];
                    } else {
                      return breakpoints;
                    }
                  },
                  []
                )}
              >
                {props.content.subMenu.icons.map(icon => (
                  <StyledSliderItem key={icon.text}>
                    <a href={icon.url}>
                      <StyledItemWrapper>
                        <StyledIcon>
                          <img src={icon.filePublicURL} alt={icon.text} />
                        </StyledIcon>
                        <StyledName>{icon.text}</StyledName>
                      </StyledItemWrapper>
                    </a>
                  </StyledSliderItem>
                ))}
              </Slick>
            </StyledSlider>
          )}
        </StyledWrapper>
      </Container>
    </StyledSubMenu>
  );
};

export default HeaderSubMenu;
