import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  children?: any;
}

const MetaGlobal: React.FC<Props> = ({ children }) => (
  <Helmet defer={false} defaultTitle="iPOS">
    <meta name="docsearch:version" content="2.0" />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
    />
    <meta
      name="description"
      content="Poznaj iPOS - urządzenie fiskalne, terminal płatniczy oraz system do zarządzania sprzedażą w jednym."
    />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="pl" />
    {/* <meta property="og:url" content={`${process.env.IPOS_ROOT_URL}`} /> */}
    {/* TODO: page url */}
    <meta property="og:title" content={`iPOS`} />
    <meta property="og:site_name" content="iPOS" />
    <meta
      property="og:description"
      content="Poznaj iPOS - urządzenie fiskalne, terminal płatniczy oraz system do zarządzania sprzedażą w jednym."
    />
    <meta property="og:image" content={`${process.env.IPOS_ROOT_URL}/ipos-banner.jpg`} />
    {/* TODO: per page? */}

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    {/* <meta property="twitter:url" content={`${process.env.IPOS_ROOT_URL}`} /> */}
    {/* TODO: page url */}
    <meta property="twitter:title" content="iPOS" />
    <meta name="twitter:site" content="@iPOS_SA" />
    <meta
      property="twitter:description"
      content="Poznaj iPOS - urządzenie fiskalne, terminal płatniczy oraz system do zarządzania sprzedażą w jednym."
    />
    <meta property="twitter:image" content={`${process.env.IPOS_ROOT_URL}/ipos-banner.jpg`} />
    {/* TODO: per page? */}

    {children}
  </Helmet>
);

export default MetaGlobal;
