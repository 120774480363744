const path = require('path');

/**
 * These is rendered in gatsby-node.js
 * and used in links
 */

const primaryMenuRoutes = [
  {
    name: 'Produkt',
    path: 0,
    component: path.resolve(__dirname, 'templates/produkt.tsx'),
  },
  {
    name: 'Cennik',
    path: 1,
    component: path.resolve(__dirname, 'templates/cennik.tsx'),
  },
  {
    name: 'Kasa fiskalna vs kasoterminal',
    nameHTML: 'Kasa fiskalna <sup>vs</sup> kasoterminal',
    path: 2,
    component: path.resolve(__dirname, 'templates/kasa-fiskalna-vs-kasoterminal.tsx'),
  },
  {
    name: 'Franczyzy',
    path: 18,
    component: path.resolve(__dirname, 'templates/franczyzy.tsx'),
  },
];

const browserOnlyRoutes = [
  {
    name: 'Konfigurator',
    path: 6,
    component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
    context: {
      id: 'krok-2',
      layout: 'configurator',
    },
  },
  {
    name: 'Konfigurator',
    path: 7,
    component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
    context: {
      id: 'krok-3',
      layout: 'configurator',
    },
  },
  {
    name: 'Konfigurator',
    path: 8,
    component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
    context: {
      id: 'krok-4',
      layout: 'configurator',
    },
  },
  {
    name: 'Konfigurator',
    path: 9,
    component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
    context: {
      id: 'colors',
      layout: 'configurator',
    },
  },
  {
    name: 'Konfigurator',
    path: 10,
    component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
    context: {
      id: 'accessories',
      layout: 'configurator',
    },
  },
];

// server-side rendered routes
const ssrRoutes = [
  ...[
    {
      name: 'Homepage',
      path: 20,
      component: path.resolve(__dirname, 'templates/index.tsx'),
    },
    {
      name: 'Konfigurator',
      path: 4,
      component: path.resolve(__dirname, 'templates/konfigurator.tsx'),
      context: {
        id: 'krok-1',
        layout: 'configurator',
      },
    },
    {
      name: 'Potwierdzenie zamówienia',
      path: 11,
      component: path.resolve(__dirname, 'templates/konfigurator-potwierdzenie.tsx'),
    },
    {
      name: 'Aktualności',
      path: 15,
      component: path.resolve(__dirname, 'templates/aktualnosci.tsx'),
    },
    {
      name: 'Demo',
      path: 12,
      component: path.resolve(__dirname, 'templates/demo.tsx'),
    },
    {
      name: 'Dofinansowanie',
      path: 17,
      component: path.resolve(__dirname, 'templates/dofinansowanie.tsx'),
    },
    {
      name: 'Demo potwierdzenie',
      path: 13,
      component: path.resolve(__dirname, 'templates/demo-potwierdzenie.tsx'),
    },
    {
      name: 'O Nas',
      path: 14,
      component: path.resolve(__dirname, 'templates/o-nas.tsx'),
    },
    {
      name: 'Inter Cars',
      path: 19,
      component: path.resolve(__dirname, 'templates/inter-cars.tsx'),
    },
    {
      name: 'Akcesoria',
      path: 16,
      component: path.resolve(__dirname, 'templates/akcesoria.tsx'),
    },
    {
      name: 'Branże',
      path: 3,
      component: path.resolve(__dirname, 'templates/branze.tsx'),
    },
  ],
  ...primaryMenuRoutes,
  ...browserOnlyRoutes,
];

const routes = [...ssrRoutes, ...browserOnlyRoutes];

// Same keys as 'routes', but the value is only the path.
const paths = Object.keys(routes).reduce((acc, route) => {
  acc[route] = routes[route].path;
  return acc;
}, {});

module.exports = {
  ssrRoutes,
  browserOnlyRoutes,
  routes,
  primaryMenuRoutes,
  paths,
};
