import * as React from 'react';
import styled from '@emotion/styled';
import { breakpoints, fluidRange, respondFrom, css } from '@styles';
import { headerHeightMobile, headerHeightDesktop } from '@components/layout/Header';
import { headerSubMenuHeightDesktop, headerSubMenuHeightMobile } from './HeaderSubMenu';

const StyledLayoutMain = styled.main<StyledLayoutMainProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  body:not(.body--menu-static) & {
    ${fluidRange('--padding-top', `${headerHeightMobile}px`, `${headerHeightDesktop}px`)};

    padding-top: var(--padding-top);

    ${({ showSubMenu }) =>
      showSubMenu &&
      css`
        padding-top: ${`calc(var(--padding-top) + ${headerSubMenuHeightMobile}px)`};
        ${respondFrom(
          breakpoints.mobileMenuThreshold,
          css`
            padding-top: ${`calc(var(--padding-top) + ${headerSubMenuHeightDesktop}px)`};
          `
        )};
      `};
  }
`;

interface LayoutMainProps extends StyledLayoutMainProps {
  className?: string;
}

interface StyledLayoutMainProps {
  showSubMenu: boolean;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className, showSubMenu }) => (
  <StyledLayoutMain className={className} showSubMenu={showSubMenu}>
    {children}
  </StyledLayoutMain>
);

export default LayoutMain;
