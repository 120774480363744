/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import * as React from 'react';

import { navigate as gatsbyNavigate } from 'gatsby';
import ReactSafeHtml from 'react-safe-html';
import qs from 'query-string';

// import TransitionLink from 'gatsby-plugin-transition-link'
// import GatsbyLink from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { GatsbyLinkProps } from 'gatsby';
import { LeasingInfo, Funding, Product } from '@store/content/types';
import { ConfiguratorBasket, BasketProduct, Accessory } from '@store/types';
import {
  getProductPrice,
  getFiscalizationPrice,
  getTariffPrice,
  getConfiguratorPricesSummary
} from './prices';

import languages from '@content/json/languages.json';
import { Agreement } from '@content/types/agreement';
import { PriceListPaymentTypes } from '@graphql/types';

declare global {
  interface Window {
    dataLayer: {
      push: Function;
      event: string;
      'gtm.start': number;
      virtualPagePath: string;
      virtualPageURL: string;
      transactionId: string | number;
      transactionTotal: string | number;
      userId: string | number;
    };
  }
}

export const Link: React.FC<GatsbyLinkProps<{}>> = (props: GatsbyLinkProps<{}>) => (
  <AniLink
    to={props.to}
    {...props}
    fade
    duration={0.3}
    entry={{
      length: 0.3,
    }}
    exit={{
      length: 0.3,
    }}
  >
    {props.children}
  </AniLink>
);

export const formatCurrency = (amount: number | string) => `${amount} zł`;
export const formatCurrencyPerMonth = (amount: number | string) => `${amount} zł/mc`;

export const decimalAdjust = (type, value, exp) => {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
};

export const navigate = (route: string): void => {
  if (typeof window !== typeof undefined) {
    gatsbyNavigate(route);
  }
};

export const leasing = (amount: number, leasing: LeasingInfo) => {
  const selfDeposit = (amount * leasing.selfdepositpercent) / 100;
  const monthCost = (amount * leasing.yearpercent) / 100 / 12;
  const installmentFromFund = (amount - selfDeposit) / leasing.installmentquantity;
  const installmentToPay = monthCost + installmentFromFund;

  return {
    installment: `${decimalAdjust('ceil', installmentToPay, -1).toFixed(2)} zł`,
    deposit: `${selfDeposit.toFixed(2)} zł`,
  };
};

export const cutArticleIntroduction = (text: string, words: number) =>
  `${text
    .split(' ')
    .splice(0, words)
    .join(' ')} […]`;

export const escapeDiacritics = (text: string) =>
  text
    .replace(/ą/g, 'a')
    .replace(/Ą/g, 'A')
    .replace(/ć/g, 'c')
    .replace(/Ć/g, 'C')
    .replace(/ę/g, 'e')
    .replace(/Ę/g, 'E')
    .replace(/ł/g, 'l')
    .replace(/Ł/g, 'L')
    .replace(/ń/g, 'n')
    .replace(/Ń/g, 'N')
    .replace(/ó/g, 'o')
    .replace(/Ó/g, 'O')
    .replace(/ś/g, 's')
    .replace(/Ś/g, 'S')
    .replace(/ż/g, 'z')
    .replace(/Ż/g, 'Z')
    .replace(/ź/g, 'z')
    .replace(/Ź/g, 'Z')
    .replace(/#/g, '')
    .replace(/ /g, '-');

export const htmlDecode = (input: string) => {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

const stringStyleToObject = (theStyleString: string) => {
  const styleObj: any = {};
  const styles: string[] = [];
  const stylesTemp = theStyleString.split(';');

  stylesTemp.forEach((s: string) => {
    if (s !== '') {
      styles.push(s);
    }
  });

  styles.forEach((item: string) => {
    const s = item.split(': ');

    if (s && s.length > 0) {
      if (s[0] === 'list-style-type') {
        styleObj.listStyleType = s[1];
      }
      // else {
      //   styleObj.s[0] = s[1];
      // }
    }
  });

  return styleObj;
};

export const SafeHtml = (html: string) => {
  const components = ReactSafeHtml.components.makeElements({});
  components.ul = ReactSafeHtml.components.createSimpleElement('ul', {
    value: true,
  });
  components.ol = ReactSafeHtml.components.createSimpleElement('ol', {
    value: true,
    style: theStyleString => {
      return ['style', stringStyleToObject(theStyleString)];
    },
    dir: true,
    start: true,
  });
  components.li = ReactSafeHtml.components.createSimpleElement('li', {
    value: true,
  });
  components.br = ReactSafeHtml.components.createSimpleElement('br', {
    value: true,
  });
  components.sup = ReactSafeHtml.components.createSimpleElement('sup', {
    value: true,
  });
  components.a = ReactSafeHtml.components.createSimpleElement('a', {
    value: true,
    href: true,
    target: true,
  });

  return <ReactSafeHtml html={html} components={components} />;
};

export const slugify = (s: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return s
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const toggleStaticMenu = (state: boolean): void => {
  const className = 'body--menu-static';
  state ? document.body.classList.add(className) : document.body.classList.remove(className);
};

/* configurator utils */

const getDiscountForFunding = (funding: Funding, basket: ConfiguratorBasket) => {
  if (funding.isPerProduct) {
    return (
      Math.min(basket.products.length || 1, funding.maxFundedProducts || Infinity) *
      funding.discount
    );
  } else {
    return funding.discount;
  }
};

export const productHasAvailableColors = (product: Product | BasketProduct): boolean => {
  if (!product) {
    return false;
  }

  const availableColors = product.availableColors;
  return (
    availableColors.length > 1 || (availableColors.length === 1 && availableColors[0].price > 0)
  );
};

export const productHasAvailableAccessories = (product: Product | BasketProduct): boolean => {
  if (typeof product === 'undefined') {
    return false;
  }

  return product.availableAccessories.length > 0;
};

export const productHasAvailablePaymentType = (product: Product | BasketProduct, paymentType: string | undefined): boolean => {
  if (!product || !paymentType) {
    return false;
  }

  return product.paymentTypes.includes(paymentType);
}

export const isSomeProductUnavailable = (basket: ConfiguratorBasket, paymentType: string | undefined): boolean => {
  return (
    basket.products.some(product => !productHasAvailablePaymentType(product, paymentType) || !getProductPrice({ product, paymentType }))
  );
};

export const isEveryProductAvailable = (basket: ConfiguratorBasket, paymentType: string | undefined): boolean => {
  return (
    basket.products.every(product => productHasAvailablePaymentType(product, paymentType) && getProductPrice({ product, paymentType }))
  );
};


export const isFundingUnavaiable = (basket: ConfiguratorBasket): boolean => {
  return (
    basket.fundings.length > 0 &&
    !!!getFundingPriceListGroup(basket)
  );
};

export const isFundingAvaiable = (basket: ConfiguratorBasket): boolean => {
  return (
    basket.fundings.length === 0 ||
    !!getFundingPriceListGroup(basket)
  );
};

export const filterFundingsWithNoZeroDiscount = (fundings: Funding[]): Funding[] => {
  return fundings.filter(funding => funding.isDiscount);
};

export const transformFundingsSelectionsToSend = (
  fundings: Funding[],
  allFundings: Funding[]
): Funding[] => {
  if (fundings.length === 1) {
    // single selection
    return fundings;
  } else if (fundings.length === 0) {
    // no selections - default
    const fund = allFundings.find(f => !f.name); // null or ''
    return fund ? [fund] : [];
  } else {
    // both selected
    const fund = allFundings.find(f => f.name === 'Idea Bank + Polska Bezgotówkowa');
    return fund ? [fund] : [];
  }
};

export const getBasketOfProductsWithLeaseElements = (basket: ConfiguratorBasket): ConfiguratorBasket => {
  if (basket.products.length === 0) return basket;

  const filteredProducts = basket.products.filter(product =>
    product.prices.some(price => price.lease && price.paymentType === PriceListPaymentTypes.casch)
  );

  return { ...basket, products: filteredProducts };
};

export const getBasketOfProductsWithoutLeaseElements = (basket: ConfiguratorBasket): ConfiguratorBasket => {
  if (basket.products.length === 0) return basket;

  const filteredProducts = basket.products.filter(product =>
    product.prices.some(price => !price.lease)
  );

  return { ...basket, products: filteredProducts };
};

export const getFundingPriceListGroup = (basket: ConfiguratorBasket): string | undefined => {
  if(basket.fundings.length === 0) return undefined;
  const priceListGroup = basket.fundings[0].apiName;
  return (
    (basket.fundings[0].isPerProduct && basket.fundings[0].maxFundedProducts >= basket.products.length)
    ? priceListGroup
    : undefined
  );
};
export interface PricesSummaryType {
  priceNet: number;
  priceGross: number;
  discount: number;
  discountGross: number;
  possibleDiscount: number;
  monthly: number;
  monthlyGross: number;
}

export const getConfiguratorPrices = (
  basket: ConfiguratorBasket,
  allFundings: Funding[]
): PricesSummaryType => {
  const productPriceSum: Array<number> = [];
  let priceNet = 0;
  let priceGross = 0;
  let discount = 0;
  let discountGross = 0;
  let possibleDiscount = 0;
  let monthly = 0;
  let monthlyGross = 0;

  const grossMultiplier = 1.23;

  if (basket.products.length > 0 || basket.accessories.length) {
    // products in basket
    if (basket.products.length) {
      basket.products.map(product => {
        productPriceSum.push(
          // product price
          product.price +
          // fiscalization
          product.fiscalization +
          // case
          (product.currentColor ? product.currentColor.price : 0) +
          // accessories
          (product.accessories.length
            ? product.accessories.map(accessory => accessory.price).reduce((a, b) => a + b)
            : 0)
        );
      });
    }
    // no products in basket - possibly accessories selected
    else {
      basket.accessories.map(accessory => {
        productPriceSum.push(accessory.price);
      });
    }

    discount =
      basket.fundings.length && basket.products.length // discount only if products added
        ? // fundings selected
        basket.fundings.map(f => getDiscountForFunding(f, basket)).reduce((a, b) => a + b)
        : // fundings not selected
        0;

    if (basket.discountCoupon) {
      discount += basket.discountCoupon.discount;
    }

    possibleDiscount = discount
      ? 0
      : allFundings
        .filter(f => !f.disabled)
        .map(f => getDiscountForFunding(f, basket))
        .reduce((a, b) => a + b, 0);

    priceNet = productPriceSum.reduce((a, b) => a + b);

    priceNet -= discount;
    priceGross = priceNet * grossMultiplier; /* 23% VAT */
    discountGross = discount * grossMultiplier;

    monthly = basket.tariff ? basket.products.length * basket.tariff.price : 0;
    monthlyGross = monthly * grossMultiplier;


  } else {
    priceNet = 0;
    priceGross = 0;
    possibleDiscount = allFundings
      .filter(f => !f.disabled)
      .map(f => getDiscountForFunding(f, basket))
      .reduce((a, b) => a + b, 0);
  }

  return {
    priceNet,
    priceGross,
    discount,
    discountGross,
    monthly,
    monthlyGross,
    possibleDiscount,
  };
};

export const setDatalayer = (location, transactionId, transactionTotal, userId) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPagePath: location.pathname,
    virtualPageURL: location.href,
    transactionId: transactionId ? transactionId : '',
    transactionTotal: transactionTotal ? transactionTotal : '',
    userId: userId ? userId : '',
  });
};

export const setDataLayerAddToCart = (
  name: string,
  id: string,
  price: number,
  category: string,
  variant = '',
  quantity = 1
) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'PLN',
      add: {
        products: [
          {
            name,
            id,
            price,
            brand: 'iPOS',
            category,
            variant,
            quantity,
          },
        ],
      },
    },
  });
};

export const setDatalayerRemoveFromCart = (
  name: string,
  id: string,
  price: number,
  category: string,
  variant = ''
) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name,
            id,
            price,
            brand: 'iPOS',
            category,
            variant,
            quantity: 1,
          },
        ],
      },
    },
  });
};

export const setDatalayerPurchase = (
  products: BasketProduct[],
  transactionId: string | number,
  coupon = '',
  totalPrices
) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          affiliation: 'iPOS Online Store',
          revenue: totalPrices.gross,
          tax: totalPrices.gross - totalPrices.net,
          shipping: 0,
          coupon,
        },
        products: products.map(product => ({
          name: product.name,
          id: product.id,
          price: product.price,
          brand: 'iPOS',
          category: 'Device',
          variant: product.currentColor ? product.currentColor.name : '',
          quantity: 1,
        })),
      },
    },
  });
};

export const setDatalayerOrderPlaced = (id: string) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({ event: 'orderPlaced', orderId: id });
};

export const setRecommendedBadge = (location: Location, recommended: string[]) => {
  const branch = qs.parse(location.search).branza;
  let addBadge;

  if (branch !== null && recommended && recommended.find(tag => tag === branch)) {
    addBadge = true;
  } else {
    addBadge = false;
  }

  return addBadge;
};

export const getBranchParameter = (location: Location) => {
  const branch = qs.parse(location.search).branza;

  if (branch) {
    return `?branza=${branch}`;
  } else {
    return '';
  }
};

/* animations */

export const getVerticalAnimTriggerOffset = (): number => {
  if (typeof window === typeof undefined) {
    return 400;
  }

  return Math.round(window.innerHeight / 2);
};

export const langLink = (langcode: string, path: string) => {
  return langcode === languages.default ? path : `/${langcode}${path}`;
};

export const prices = {
  getProductPrice,
  getFiscalizationPrice,
  getTariffPrice,
  getConfiguratorPricesSummary
}

// Form helper
export const filterAgreementsByPaymentType = (
  agreements: Agreement[],
  paymentType: string
): Agreement[] => {
  return agreements.filter(agreement => agreement.paymentTypes.includes(paymentType));
};

export const isAgreementsAccepted = (
  agreements: Agreement[],
  agreementsChecked: Array<String>,
  paymentType: string
): boolean => {
  return (
    agreements &&
    filterAgreementsByPaymentType(agreements, paymentType).every(
      agreement => agreementsChecked && agreementsChecked.includes(agreement.id)
    )
  );
};
