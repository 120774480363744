import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import HeaderMenuContent from './HeaderMenuContent';
import { mixins, colors, dimensions, fluidRange, respondTo, breakpoints } from '@styles';
import Container from '@components/layout/Container';
import IconHamburger from '../../assets/svg-inline/hamburger.svg';
import BodyClassName from 'react-body-classname';
import { MenuData } from '@content/types/layout';

const HamburgerMenuContainer = styled.div`
  line-height: 1;
`;

const HamburgerMenuOverlay = styled.div`
  ${mixins.absoluteFill}
  position: fixed;
  ${fluidRange(
    'top',
    '50px',
    '100px'
  )} /* below header */
  z-index: -1; /* "outer" z-index set in Header */

  overflow: hidden;
  pointer-events: none;

  .open > & {
    pointer-events: inherit;
  }
`;

const HamburgerMenuContent = styled.div`
  padding: 20px ${dimensions.containerPadding}px;
  display: flex;
  height: 100%;
  background: ${colors.white};

  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  ${mixins.transitionDefault}

  .open > * > & {
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  > * {
    display: flex;
  }

  ul {
    li {
      font-size: ${dimensions.fontSize.large}px;
      line-height: 2.8;

      ${respondTo(
        breakpoints.sm,
        css`
          font-size: ${dimensions.fontSize.regular}px;
        `
      )}

      a {
        display: block;
        background-color: transparent;

        margin: 0 -${dimensions.containerPadding}px;
        padding: 0 ${dimensions.containerPadding}px;

        ${mixins.transitionDefault}

        &:hover,
        &:active,
        &:focus {
          background-color: ${colors.ui.whisper};
        }
      }

      &.iconed {
        line-height: 3.6em;
      }
    }
  }
`;

const StyledTriggerIcon = styled.a`
  display: inline-block;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none !important;
  padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  left: ${dimensions.containerPadding}px;

  span {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    padding: 5px 0 0;
  }
`;

const StyledHeaderMenuContent = styled(HeaderMenuContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
`;

interface HamburgerMenuProps {
  className?: string;
  content: MenuData;
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;
}

interface HamburgerMenuState {
  open: boolean;
}

class HamburgerMenu extends React.Component<HamburgerMenuProps, HamburgerMenuState> {
  constructor(props: HamburgerMenuProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    return (
      <BodyClassName className={this.state.open ? 'noscroll' : ''}>
        <HamburgerMenuContainer
          className={`
      ${this.props.className} 
      ${this.state.open ? 'open' : ''}
    `}
        >
          <HamburgerMenuOverlay>
            <HamburgerMenuContent>
              <Container>
                <StyledHeaderMenuContent
                  content={this.props.content}
                  clickHandler={this.toggle.bind(this)}
                  langcode={this.props.langcode}
                  urls={this.props.urls}
                  otherLangUrl={this.props.otherLangUrl}
                />
              </Container>
            </HamburgerMenuContent>
          </HamburgerMenuOverlay>

          <StyledTriggerIcon onClick={this.toggle.bind(this)}>
            <IconHamburger />
            <span>Menu</span>
          </StyledTriggerIcon>
        </HamburgerMenuContainer>
      </BodyClassName>
    );
  }
}

export default HamburgerMenu;
