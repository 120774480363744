import React, { Component } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { colors, mixins, respondFrom, respondTo, breakpoints, css } from '@styles';
import Cookies from 'js-cookie';
import languages from '@content/json/languages.json';
import * as utils from '@utils';

const LanguageSelectorWrapper = styled.div`
  position: relative;
  margin: 20px 0 50px 0;

  ${respondFrom(
    breakpoints.xl,
    css`
      margin: 0;
    `
  )};
`;

const LanguageSelectorTitle = styled.div<MenuOpenedProps>`
  cursor: pointer;
  padding-right: 20px;
  font-weight: 500;
  ${mixins.transitionDefault};
  display: none;

  &::before {
    content: '';
    position: absolute;
    top: -7px;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    border: 2px solid ${colors.green.dark};
    border-left: 0;
    border-top: 0;
    transform: ${props =>
      props.menuOpened ? 'translate(0, 5px) rotate(225deg)' : 'rotate(45deg)'};
    transform-origin: center;
    ${mixins.transitionDefault};
  }

  &:hover {
    color: ${colors.green.dark};
  }

  ${respondFrom(
    breakpoints.xl,
    css`
      display: block;
    `
  )};
`;

const LanguageSelectorList = styled.ul<MenuOpenedProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  transform-origin: top;

  ${mixins.transitionDefault};
  opacity: ${props => (props.menuOpened ? '1' : '0')};
  transform: ${props => (props.menuOpened ? 'scale(1)' : 'scale(0)')};

  li {
    position: relative;
    line-height: 30px;
    background-color: ${colors.white};
    text-align: center;
    margin-right: 30px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -15px;
      width: 1px;
      height: 18px;
      margin: auto;
      background-color: ${colors.ui.light};
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }

    ${respondFrom(
      breakpoints.xl,
      css`
        width: 80px;
        margin: 0;

        &::after {
          content: none;
        }
      `
    )};

    a {
      &::before,
      &::after {
        content: none !important;
      }

      &:hover {
        ${mixins.transitionDefault};
        color: ${colors.green.dark};
      }

      &.active {
        color: ${colors.green.dark};
      }
    }
  }

  ${respondFrom(
    breakpoints.xl,
    css`
      position: absolute;
      top: 100%;
      right: -5px;
      flex-flow: column;
      background-color: ${colors.white};
    `
  )};

  ${respondTo(
    breakpoints.xl,
    css`
      opacity: 1;
      transform: none;
    `
  )};
`;

interface LanguageSelectorProps {
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;
}

interface MenuOpenedProps {
  menuOpened: boolean;
}

class LanguageSelector extends Component<LanguageSelectorProps> {
  state = {
    menuOpened: false,
    activeLang: null,
  };

  componentDidMount() {
    const activeLang = this.props.langcode.toUpperCase();
    this.setState({ activeLang });
  }

  onLocationChange = (e, lang: string) => {
    e.preventDefault();

    Cookies.set('ipos-language', lang, { expires: 365 });

    if (typeof window !== 'undefined') {
      window.location.href = e.target.href;
    }
  };

  render() {
    const langSelectorList = languages.codes;
    return (
      <>
        {this.state.activeLang && (
          <LanguageSelectorWrapper>
            <LanguageSelectorTitle
              menuOpened={this.state.menuOpened}
              onClick={() => this.setState({ menuOpened: !this.state.menuOpened })}
            >
              {this.state.activeLang}
            </LanguageSelectorTitle>
            <LanguageSelectorList menuOpened={this.state.menuOpened}>
              {this.props.otherLangUrl &&
                langSelectorList.map(lang => (
                  <li key={lang}>
                    <a
                      href={utils.langLink(lang, this.props.otherLangUrl[lang])}
                      className={lang.toUpperCase() === this.state.activeLang ? 'active' : ''}
                      onClick={e => this.onLocationChange(e, lang)}
                    >
                      {lang.toUpperCase()}
                    </a>
                  </li>
                ))}
            </LanguageSelectorList>
          </LanguageSelectorWrapper>
        )}
      </>
    );
  }
}

export default LanguageSelector;
