import { graphql, useStaticQuery } from 'gatsby';
import * as layoutTransformers from '@content/transformers/LayoutTransformers';
import { LayoutData } from '@content/types/layout';

const LayoutQuery = (): LayoutData => {
  const layoutData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityMenu {
        edges {
          node {
            field_settings_text_1
            field_settings_text_2
            field_settings_text_3
            field_settings_text_4
            field_settings_text_5
            field_settings_text_6
            field_settings_text_7
            field_settings_tresc {
              value
            }
            langcode
            field_optional_label
            field_optional_url
            relationships {
              field_menu_icons_belt {
                field_icons_belt_enabled
                field_icons_belt_heading
                relationships {
                  field_icons_belt_icons {
                    relationships {
                      field_linked_icon_icon {
                        localFile {
                          publicURL
                        }
                      }
                    }
                    field_linked_icon_text
                    field_linked_icon_url
                  }
                }
                field_icons_belt_show_urls
              }
            }
          }
        }
      }
      allSiteSettingEntityFooter {
        edges {
          node {
            langcode
            relationships {
              field_footer_sections {
                field_section_header
                relationships {
                  field_section_components {
                    ... on paragraph__heading_and_agreements {
                      field_heading_and_agrmnt_heading
                      relationships {
                        field_agreements {
                          agreement_pdf_title
                          relationships {
                            agreement_pdf {
                              localFile {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on paragraph__heading_and_links {
                      field_heading_and_links_heading
                      relationships {
                        field_heading_and_links_links {
                          field_link_with_text_text
                          field_link_with_text_link
                        }
                      }
                    }
                    ... on paragraph__heading_and_text {
                      field_heading_and_text_heading
                      field_heading_and_text_text {
                        value
                      }
                      relationships {
                        field_heading_and_text_links {
                          field_link_with_text_text
                          field_link_with_text_link
                        }
                      }
                    }
                  }
                }
              }
              field_footer_social_links {
                field_social_link {
                  title
                  uri
                }
                relationships {
                  field_social_icon {
                    localFile {
                      publicURL
                      childImageSharp {
                        fixed(width: 32, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allSiteSettingEntityPanelCookies {
        edges {
          node {
            langcode
            field_settings_tresc {
              value
            }
            field_settings_button_text
          }
        }
      }
      allSiteSettingEntityAdministration {
        edges {
          node {
            field_translation_boolean
          }
        }
      }
    }
  `);

  return layoutTransformers.transformLayoutData(layoutData);
};

export default LayoutQuery;
