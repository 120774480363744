import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createExpirationTransform from 'redux-persist-transform-expire';
import { defaultState } from './configurator/reducers';
import * as configuratorSelectors from './configurator';

import { composeEnhancers } from './utils';
import rootReducer from './reducers';
import { RootState, RootStateWithSelectors } from './types';
// import services from '../services';

// persist / expire config
const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt',
  defaultState, // ?? should be configurator: defaultState, but it nests for some reason
});
const persistConfig: PersistConfig<RootState> = {
  key: 'ipos',
  storage,
  whitelist: ['configurator'],
  transforms: [expireTransform],
  version: 1, // update, if store structure changes to prevent old data be usable and use 'migrate'
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure middlewares
const middlewares = [thunk];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

// create store
const store = createStore(persistedReducer, initialState, enhancer);

const persistor = persistStore(store, {});

// This is a quick fix for a problem where old localStorage contains data that causes errors
// TODO: This should probably be done with the redux-persist package
if (typeof localStorage !== 'undefined') {
  const localStorageString = localStorage.getItem(process.env
    .IPOS_LOCAL_STORAGE_VERSION_KEY as string);
  if (localStorageString) {
    const localStorageObject = JSON.parse(localStorageString);
    if (localStorageObject.version !== process.env.IPOS_APP_VERSION) {
      persistor.purge();
    }
  } else {
    persistor.purge();
  }
  localStorage.setItem(
    process.env.IPOS_LOCAL_STORAGE_VERSION_KEY as string,
    JSON.stringify({ version: process.env.IPOS_APP_VERSION })
  );
}

// epicMiddleware.run(rootEpic);

// export store singleton instance
export default { store, persistor };

/* ---- */
export const enhanceStateWithSelectors = (state: RootState): RootStateWithSelectors => {
  return {
    configurator: {
      ...state.configurator,
      selectors: configuratorSelectors.getConfiguratorSelectors(state.configurator),
    },
    cennik: {
      ...state.cennik,
      selectors: configuratorSelectors.getConfiguratorSelectors(state.cennik),
    },
    content: state.content,
  };
};
