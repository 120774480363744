import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@utils';
import * as utils from '@utils';

import logo from '@assets/svg/logo.svg';

const StyledHeaderLogo = styled.div<StyleProps>`
  height: 100%;
  display: inline-block;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  margin-right: ${props => (props.noMargin ? '0' : '70px')};

  img {
    vertical-align: middle;
    height: 32%;
    max-height: 28px;
    width: auto;
  }
`;

interface LogoProps {
  noMargin?: boolean;
  langcode: string;
  urls: Array<string>;
  target?: string;
}

interface StyleProps {
  noMargin?: boolean;
}

export default (props: LogoProps) => (
  <>
    {props.urls && props.urls[20] && (
      <StyledHeaderLogo noMargin={props.noMargin}>
        {props.target && props.target != '' ? (
          <a href={utils.langLink(props.langcode, props.urls[20])} target={props.target}>
            <img src={logo} alt="IPOS logo" />
          </a>
        ) : (
          <Link to={utils.langLink(props.langcode, props.urls[20])}>
            <img src={logo} alt="IPOS logo" />
          </Link>
        )}
      </StyledHeaderLogo>
    )}
  </>
);
