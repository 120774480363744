import * as React from 'react';

import LayoutRoot from '@components/layout/LayoutRoot';
import LayoutMain from '@components/layout/LayoutMain';
import Header from '@components/layout/Header';
import Footer from '@components/layout/Footer';
import { LayoutData } from '@content/types/layout';

interface MainLayout {
  layoutData: LayoutData;
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;
  showSubMenu: boolean;
}

const MainLayout: React.FC<MainLayout> = ({
  layoutData,
  children,
  langcode,
  urls,
  otherLangUrl,
  showSubMenu,
}) => {
  return (
    <LayoutRoot>
      <Header
        content={layoutData.menu[langcode]}
        langcode={langcode}
        urls={urls}
        otherLangUrl={otherLangUrl}
        showSubMenu={showSubMenu}
      />

      <LayoutMain showSubMenu={showSubMenu}>{children}</LayoutMain>

      <Footer content={layoutData.footer[langcode]} langcode={langcode} urls={urls} />
    </LayoutRoot>
  );
};

export default MainLayout;
