import * as React from 'react';
import BodyClassName from 'react-body-classname';

import LayoutRoot from '@components/layout/LayoutRoot';

const ConfiguratoreLayout: React.FC = ({ children }) => (
  <BodyClassName className="body-configurator">
    <LayoutRoot>
      <div>{children}</div>
    </LayoutRoot>
  </BodyClassName>
);

export default ConfiguratoreLayout;
