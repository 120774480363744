import { createAction } from 'redux-act';
import {
  Product,
  Accessory,
  ProductColor,
  Funding,
  Tariff,
  FormData,
  FormCalendar,
  FormValidation,
} from '@store/content/types';
import { ActionMeta } from '../configurator/actions';
import { ConfiguratorBasket, BasketDiscountCoupon } from '@store/types';

const payloadReducer = (a: any): any => a;
const metaReducer = (): ActionMeta => ({ reducer: 'cennik' });

/* TODO: reuse / share same actions as configurator */

export const basketAddProduct = createAction<Product>(
  'Add product to basket',
  payloadReducer,
  metaReducer
);
export const basketRemoveProduct = createAction<number>(
  'Remove product from basket',
  payloadReducer,
  metaReducer
);

export const basketAddAccessory = createAction<{
  accessory: Accessory;
  productIndex?: number;
}>('Add accessory to basket - current or given product', payloadReducer, metaReducer);

export const basketRemoveAccessory = createAction<{
  accessory: Accessory;
  productIndex: number;
}>('Remove accessory from basket - current or given product', payloadReducer, metaReducer);

export const setCurrentProductIndex = createAction<number>(
  'Change current product index',
  payloadReducer,
  metaReducer
);

export const basketSetCurrentColor = createAction<ProductColor>(
  'Set current color to product',
  payloadReducer,
  metaReducer
);

export const basketAddFunding = createAction<Funding>(
  'Add funding to basket',
  payloadReducer,
  metaReducer
);
export const basketRemoveFunding = createAction<Funding>(
  'Remove funding from basket',
  payloadReducer,
  metaReducer
);

export const basketSetTariff = createAction<Tariff | null>(
  'Set tarrif',
  payloadReducer,
  metaReducer
);

export const basketSetFiscalization = createAction(
  'Set fiscalization',
  payloadReducer,
  metaReducer
);

export const getFormData = createAction<FormData>(
  'Get data from contact form',
  payloadReducer,
  metaReducer
);
export const getFormCalendar = createAction<FormCalendar>(
  'Get data from calendar',
  payloadReducer,
  metaReducer
);
export const getFormValidation = createAction<FormValidation>(
  'Get form validation data',
  payloadReducer,
  metaReducer
);

export const setPaymentType = createAction<string>('Set payment type', payloadReducer, metaReducer);

export const toggleSummaryPanel = createAction<boolean>(
  'Toggle summary panel on any input focus or blur',
  payloadReducer,
  metaReducer
);

export const setLoading = createAction<boolean>('Set loading state', payloadReducer, metaReducer);

export const replaceBasket = createAction<ConfiguratorBasket>(
  'Replace configurator basket',
  payloadReducer,
  metaReducer
);

export const clearBasket = createAction('Replace configurator basket', payloadReducer, metaReducer);

export const setDiscountCoupon = createAction<BasketDiscountCoupon>(
  'Set discount coupon',
  payloadReducer,
  metaReducer
);

export const removeDiscountCoupon = createAction(
  'Remove discount coupon',
  payloadReducer,
  metaReducer
);

export const checkNipControlSum = createAction(
  'Check NIP control sum',
  payloadReducer,
  metaReducer
);
