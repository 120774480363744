import React from 'react';
import styled from '@emotion/styled';

import Icon from './Icon';
import { mixins } from '@styles';

const StyledScrollToTop = styled.a`
  ${mixins.transitionDefault}

  .scrolltop-icon {
    ${mixins.transitionDefault}
  }

  &:hover,
  &:active,
  &:focus {
    background-position: -20% center;

    .scrolltop-icon {
      transform: translate(-50%, -60%);
    }
  }
  &:active {
    transform: translateY(-1px);
  }
`;

interface ScrollToTopProps {
  className?: string;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ className }) => (
  <StyledScrollToTop
    className={className}
    onClick={() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }}
  >
    <Icon className="scrolltop-icon" type="arrow-up" inheritColor={true} />
  </StyledScrollToTop>
);

export default ScrollToTop;
