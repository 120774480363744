import { createReducer } from 'redux-act';
// import * as contentActions from './actions';
import { ContentState } from './types';

export const defaultState: ContentState = {
  products: [],
};

const contentReducer = createReducer<typeof defaultState>({}, defaultState);

// contentReducer.on(contentActions.add, (state, payload) => ({
// 	...state,
// 	test: payload   // typed payload!
// }));

export default contentReducer;
