import { dimensions, animation, breakpoints, colors } from './variables';
import { css, SerializedStyles } from '@emotion/core';
export { css } from '@emotion/core';

import { fluidRange as polishedFluidRange } from 'polished';

export const getEmSize = (size: number) => size / dimensions.fontSize.regular;

/* --- animation helpers */

const transitionDefaultProperties = `transform, opacity, visibility, color, background-color, stroke`;

export const transitionDefault = css`
  transition: ${animation.fast}ms ease-out;
  transition-property: ${transitionDefaultProperties};
  will-change: ${transitionDefaultProperties};
`;
/* --- repeating elements */

export const fontSmoothing = css`
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
`;

export const gradient = (color1: string, color2: string) => css`
  background-image: linear-gradient(to right, ${color1} 0%, 
    ${color2} 50%, ${color1} 100%);
  background-repeat: repeat-x;
  color: ${colors.white};
  ${fontSmoothing}

  transition: ${animation.fast}ms ease-out;
  transition-property: background-position, ${transitionDefaultProperties};
  will-change: background-position, ${transitionDefaultProperties};
  background-size: 200% auto;
  background-position: left center;

  &:hover, &:active, &:focus {
    background-position: -20% center;
  }
`;

export const gradientGreen = css`
  ${gradient(colors.green.default, colors.green.light)}
`;

export const gradientBlue = css`
  ${gradient(colors.blue.default, colors.blue.light)}
`;

export const borderWhenActive = css`
  ${transitionDefault}
  transition-property: border-color, box-shadow, ${transitionDefaultProperties};

  &.active {
    border-color: ${colors.blue.default};
  }
`;

export const borderOnHover = css`
  ${transitionDefault}
  transition-property: border-color, box-shadow, ${transitionDefaultProperties};
  border: transparent solid 2px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border-color: ${colors.blue.default};
    transform: translateY(-1px);
    box-shadow: 0 4px 2px -2px rgba(29, 162, 57, 0.1);
  }

  &.inactive,
  .inactive > &,
  &.disabled,
  .disabled > & {
    transform: none !important;
    cursor: default;
    box-shadow: none;
  }
`;

export const negateContainerPadding = css`
  margin-left: -${dimensions.containerPadding}px;
  margin-right: -${dimensions.containerPadding}px;
`;

/* --- styling helpers */

export const absoluteFill = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const absoluteCenter = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const linkUnderline = css`
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.05em;
    height: 1px;
    background-color: currentColor;
    opacity: 0;

    transform-origin: left center;
    transform: scaleX(0);
    ${transitionDefault};
  }

  &:hover {
    &:after {
      transform: scale(1);
      opacity: 0.8;
    }
  }
`;

/* --- responsive helpers */

export const fluidRange = (
  prop: string,
  fromSize: string | number,
  toSize: string | number,

  minScreen?: number,
  maxScreen?: number
) => css`
  ${polishedFluidRange(
    { prop, fromSize, toSize },
    `${minScreen || breakpoints.sm}px`,
    `${maxScreen || breakpoints.xl}px`
  )}
`;

export const respondFrom = (minW: number, content: SerializedStyles) => css`
  @media (min-width: ${minW}px) {
    ${content}
  }
`;
export const respondTo = (maxW: number, content: SerializedStyles) => css`
  @media (max-width: ${maxW - 1}px) {
    ${content}
  }
`;

export const listDefault = css`
  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 5px 0;
      position: relative;
      padding-left: 35px;

      &::before {
        content: '•';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
      }
    }
  }
`;

export const mixins = {
  transitionDefault,
  gradientGreen,
  gradientBlue,
  gradient,
  fontSmoothing,
  borderWhenActive,
  borderOnHover,
  negateContainerPadding,

  absoluteFill,
  absoluteCenter,
  linkUnderline,

  fluidRange,
  respondFrom,
  respondTo,
  listDefault,
  css,
};
