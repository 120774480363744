import { combineReducers } from 'redux';

import contentReducer, { defaultState as contentDefaultState } from './content/reducers';
import configuratorReducer, {
  defaultState as configuratorDefaultState,
} from './configurator/reducers';
import { Reducer, Action } from 'redux-act';
import { ConfiguratorState } from './configurator/types';

// wraps reducer to distinguish between actions with the same reducer implementation
const createConfiguratorNamedWrapperReducer = (
  reducerFunction: Reducer<ConfiguratorState, Action<any, any>>,
  reducerName: string
): ((a: ConfiguratorState, b: Action<any, any>) => /*ConfiguratorState*/ any) => {
  // TODO? 'any' should be ConfiguratorState, where does undefined come from?
  return (state: ConfiguratorState, action) => {
    const { meta } = action;
    const isInitializationCall = state === undefined;

    if (meta && meta.reducer !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
};

const rootReducer = combineReducers({
  configurator: createConfiguratorNamedWrapperReducer(configuratorReducer, 'configurator'),
  cennik: createConfiguratorNamedWrapperReducer(configuratorReducer, 'cennik'),
  content: contentReducer,
});

export const defaultState = {
  configurator: configuratorDefaultState,
  cennik: configuratorDefaultState,
  content: contentDefaultState,
};

export default rootReducer;
