import * as React from 'react';
import styled from '@emotion/styled';

import { Link } from '@utils';
import { colors, dimensions, mixins } from '@styles';
import { primaryMenuRoutes } from '../../routes.js';

import iconDemo from '@assets/svg/demo-icon.svg';
import iconLogin from '@assets/svg/login-icon.svg';
import iconShop from '@assets/svg/shop-icon.svg';
import { MenuData } from '@content/types/layout.js';
import LanguageSelector from '@components/layout/LanguageSelector';
import * as utils from '@utils';

const StyledHeaderMenuContent = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      sup {
        font-size: 70%;
        vertical-align: middle;
        color: ${colors.text.medium};
        top: 0;
        padding: 0 0.2em;
        line-height: 1;
      }
      a {
        position: relative;
        color: ${colors.text.default};
        text-transform: uppercase;
        font-weight: 500;
        vertical-align: top;
        position: relative;
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          background-color: ${colors.green.dark};
          opacity: 0;
          transform: scaleY(0);
          transform-origin: bottom center;

          ${mixins.transitionDefault};
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
          text-decoration: none;
        }

        &.active {
          &::before {
            opacity: 1;
            transform: scaleY(1);
          }
        }

        &.link-highlighted {
          ${mixins.gradientGreen}

          &::after {
            content: none;
          }
        }
      }

      &.iconed.buy-rent {
        a {
          white-space: normal;
        }

        span {
          text-transform: none;
        }
        
        @media (min-width: 1201px) {
          a {
            max-width: 170px;
          }

          span {
            max-width: 97px;
            line-height: 1.3;
            vertical-align: middle;
          }
        }
      }

      &.iconed {
        img {
          vertical-align: middle;
          margin-right: 12px;
          position: relative;
        }
        span {
          position: relative;
          display: inline-block;
        }
        small {
          position: absolute;
          top: -16px;
          text-transform: none;
          font-size: ${dimensions.fontSize.tiny}px;
        }
        &:nth-of-type(1) {
          img {
            top: -1px;
          }
        }
        &:nth-of-type(2) {
          img {
            top: -4px;
          }
        }
      }
    }
  }
`;

interface MenuContentProps {
  className?: string;
  content: MenuData;
  langcode: string;
  urls: Array<string>;
  otherLangUrl: any;

  clickHandler?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const MenuContent: React.FC<MenuContentProps> = ({
  content,
  clickHandler,
  className,
  langcode,
  urls,
  otherLangUrl,
}) => {
  return (
    <StyledHeaderMenuContent className={className}>
      <ul>
        {primaryMenuRoutes.map((route, index) => {
          return (
            <li key={route.name}>
              <Link
                to={utils.langLink(langcode, urls ? urls[route.path] : '/')}
                activeClassName="active"
                onClick={clickHandler}
              >
                {utils.SafeHtml(content ? content[`text${index + 1}`] : '')}
              </Link>
            </li>
          );
        })}
        {content.optionalField.label &&
          content.optionalField.label !== '' &&
          content.optionalField.url &&
          content.optionalField.url !== '' && (
            <li>
              <Link
                to={utils.langLink(langcode, content.optionalField.url)}
                activeClassName="active"
                onClick={clickHandler}
              >
                {utils.SafeHtml(content.optionalField.label)}
              </Link>
            </li>
          )}
      </ul>

      <ul>
        <li className="iconed buy-rent">
          <Link
              to={utils.langLink(langcode, urls ? urls[4] : '/')}
              className="link-highlighted"
          >
            <img src={iconShop} alt="" />
            <span>{content ? content.text5 : ''}</span>
          </Link>
        </li>
        <li className="iconed">
          <Link
            to={utils.langLink(langcode, urls ? urls[12] : '/')}
            onClick={clickHandler}
          >
            <img src={iconDemo} alt="" />
            <span>
              <small>{content ? content.text6 : ''}</small>
              <span>{content ? content.text7 : ''}&nbsp;&nbsp;</span>
            </span>
          </Link>
        </li>
        <li className="iconed">
          <a href="https://iposweb.pl/#/login" target="_blank" rel="noopener noreferrer">
            <img src={iconLogin} alt="" />
            <span>
              <small>{content ? content.text6 : ''}</small>
              <span>{content ? content.text8 : ''}</span>
            </span>
          </a>
        </li>
        {content.translationSwitch ? (
          <li>
            <LanguageSelector langcode={langcode} urls={urls} otherLangUrl={otherLangUrl} />
          </li>
        ) : null}
      </ul>
    </StyledHeaderMenuContent>
  );
};

export default MenuContent;
