import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import * as utils from '@utils';
import Button from '@components/common/Button';
import Container from '@components/layout/Container';

import cookieIcon from '@assets/svg/cookies.svg';
import { CookieBannerData } from '@content/types/layout';

const CookieBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
  background-color: ${colors.gray};
  transform: translateY(100%);

  ${mixins.transitionDefault};

  &.is-opened {
    transform: translateY(0);
  }
`;

const CookieBannerInner = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 60px 0 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      align-items: center;
      flex-flow: row;
      padding: 30px 0;
      margin-bottom: 15px;
    `
  )}

  ${respondFrom(
    breakpoints.xxl,
    css`
      margin: 0;
    `
  )}

  p {
    margin: 0;
  }

  img {
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 80px;

    ${respondFrom(
      breakpoints.lg,
      css`
        left: -40px;
        bottom: -25px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        left: -100px;
        bottom: 0;
      `
    )}
  }
`;

const StyledButton = styled(Button)`
  margin: 15px 0 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 0 0 0 30px;
    `
  )}
`;

const CookieText = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      width: 73%;
    `
  )}

  ${respondFrom(
    breakpoints.xxl,
    css`
      width: auto;
      max-width: 1000px;
    `
  )}
`;

const CloseBanner = styled.button`
  position: absolute;
  top: 20px;
  right: 0;
  margin: auto;
  background: transparent;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;

  ${respondFrom(
    breakpoints.lg,
    css`
      top: 0;
      bottom: 0;
    `
  )}

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 3px;
    height: 30px;
    background-color: ${colors.green.dark};
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

interface CookieBannerProps {
  opened: boolean;
  acceptCookies: Function;
  closeCookieBanner: Function;
  content: CookieBannerData;
}

const CookieBanner: React.FC<CookieBannerProps> = ({
  opened,
  acceptCookies,
  closeCookieBanner,
  content,
}) => {
  return (
    <>
      {content && (
        <CookieBannerWrapper className={opened ? 'is-opened' : ''}>
          <Container>
            <CookieBannerInner>
              <CookieText>{utils.SafeHtml(content.paragraph)}</CookieText>
              <div>
                <StyledButton icon="arrow-right" color="text" onClick={() => acceptCookies()}>
                  {content.button}
                </StyledButton>
              </div>
              <img src={cookieIcon} alt="" />
              <CloseBanner onClick={() => closeCookieBanner()} />
            </CookieBannerInner>
          </Container>
        </CookieBannerWrapper>
      )}
    </>
  );
};

export default CookieBanner;
